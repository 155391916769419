import React, { useContext, useState, useEffect } from 'react'
import { Container, makeStyles } from '@material-ui/core'
import { BackButton, Spacer, Typography, CircularProgress, Section, Icon, Button, Progress } from '../components/library'
import { ImpactContext } from '../components/Layout'
import theme from '../theme'
import Goals from '../components/Goals'
import { navigate } from 'gatsby'

const useStyles = makeStyles(() => ({
  top: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  title: {
    textAlign: 'left'
  },
  header: {
    padding: theme.spacing(0, 0, 1),
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    minWidth: 360,
    '& > * ': {
      marginRight: '0.5em'
    },
    '& .MuiTypography-caption': {
      marginRight: 0,
      width: '100%'
    }
  },
  cards: {
    '& > *': {
      marginTop: theme.spacing(3)
    }
  }
}))

function GettingStarted() {
  const classes = useStyles()
  const { gettingStartedDone, gettingStarted, gettingStartedConfig, weeks } = useContext(ImpactContext)
  const [currentStep, setCurrentStep] = useState(null)

  useEffect(() => {
    let step = 0
    for (let index = 0; index < gettingStartedConfig.length; index++) {
      const conf = gettingStartedConfig[index]
      if (!gettingStarted.includes(conf.key)) {
        step = index
        break
      }
    }
    const step_conf = gettingStartedConfig[step]
    setCurrentStep({ ...step_conf, step })
  }, [gettingStarted])

  if (currentStep === null) return <CircularProgress />

  if (gettingStartedDone) navigate('/')

  let cards = []
  if (currentStep.step === 0) {
    cards.push(
      <Section
        footer={
          <Typography variant="caption">
            You can earn 2x <Icon name="bone" bg={theme.palette.purple.main} fg="#fff" />
          </Typography>
        }
      >
        <Typography variant="body1" align="left">
          Use your IMPACT watch to track when asthma symptoms occur (cough, tight chest, wheezing, etc). Pair your watch with the app now
          and sync daily.
        </Typography>
      </Section>
    )
    cards.push(
      <Button variant="contained" color="primary" to="/pair-wearable">
        Start Pairing
      </Button>
    )
  } else if (currentStep.step === 1) {
    cards.push(<Goals week={weeks[0]} />)
    cards.push(
      <Section
        footer={
          <Typography variant="caption">
            Earn up to 2x per goal <Icon name="bone" bg={theme.palette.purple.main} fg="#fff" />
          </Typography>
        }
      >
        <Typography variant="body1" align="left">
          Add more goals and check in at the end of the week to earn more bones!
        </Typography>
      </Section>
    )
    cards.push(
      <Button
        variant="contained"
        color="primary"
        onClick={e => {
          navigate('/goal-form', { state: { backTo: '/getting-started' } })
        }}
      >
        Add goal
      </Button>
    )
  } else if (currentStep.step === 2) {
    cards.push(
      <Section
        footer={
          <Typography variant="caption">
            You can earn up 1x <Icon name="bone" bg={theme.palette.purple.main} fg="#fff" />
          </Typography>
        }
      >
        <Typography variant="body1" align="left">
          The Childhood Asthma Control Test (C-ACT) is a seven-item questionnaire used to assess asthma control in children 4-11 years old.
        </Typography>
      </Section>
    )
    cards.push(
      <Button
        variant="contained"
        color="primary"
        onClick={e => {
          navigate('/c-act-form', { state: { forceBackTo: '/getting-started' } })
        }}
      >
        Take C-ACT Now
      </Button>
    )
  } else if (currentStep.step === 3) {
    cards.push(
      <Section
        footer={
          <Typography variant="caption">
            You can earn up 1x <Icon name="bone" bg={theme.palette.purple.main} fg="#fff" />
          </Typography>
        }
      >
        <Typography variant="body1" align="left">
          Complete a spirometry test using the spirometer app.
        </Typography>
      </Section>
    )
    cards.push(
      <Button
        variant="contained"
        color="primary"
        onClick={e => {
          navigate('/spirometry-test-form', { state: { forceBackTo: '/getting-started' } })
        }}
      >
        Take the Test
      </Button>
    )
  }

  return (
    <Container maxWidth="md">
      <Spacer />
      <div className={classes.top}>
        <div>
          <BackButton to="/">Home</BackButton>
          <Spacer amount="3.5" />
          <div className={classes.header}>
            {currentStep.step === 3 ? (
              <Typography variant="h1" shade="dark" className={classes.title}>{`Last step!`}</Typography>
            ) : (
              <Typography variant="h1" shade="dark" className={classes.title}>{`Step ${currentStep.step + 1}: ${
                currentStep.label
              }`}</Typography>
            )}
            {/* <Typography variant="h1" shade="dark" className={classes.title}>{`Step ${currentStep.step + 1}: ${currentStep.label}`}</Typography> */}
          </div>
        </div>
        <Progress step={gettingStarted.length} total={gettingStartedConfig.length} />
      </div>
      <div className={classes.cards}>{cards}</div>
    </Container>
  )
}

export default GettingStarted
